<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据看板</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <!-- <el-button type="primary" @click="add(null, false)">新增物流公司</el-button> -->
        </el-col>
      </el-row>
      
      <el-row style="margin-top: 10px;">
        <el-col style="display: flex;">
          <div style="display: flex; align-items: center;margin-right: 40px;">
           <div class="status-item" :class="{active: selectDateValue == item.value}" v-for="(item, index) in statusList" :key="index" @click="selectDate(item.value)">{{item.key}}</div>
          </div>
          <el-date-picker class="search-date" v-model="params.beginDate" type="date" placeholder="开始时间" value-format="yyyy-MM-dd"></el-date-picker>
            <span style="line-height: 38px;margin: 0 5px;">-</span>
            <el-date-picker class="search-date" v-model="params.endDate" type="date" placeholder="结束时间" value-format="yyyy-MM-dd"></el-date-picker>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px;">
        <el-col style="display: flex;">
          <el-input v-model="params.goodsId" class="search-input" placeholder="商品ID" clearable />
          <el-input v-model="params.remark" class="search-input" placeholder="商品描述" clearable />
          <el-select v-model="params.groupId" placeholder="商品分组" class="search-select" >
            <el-option v-for="item in groupList" :key="item.groupId" :label="item.groupName" :value="item.groupId"/>
          </el-select>
          <el-button size="mini" @click="search" type="primary" style="margin-left: 10px;">搜索</el-button>
        </el-col>
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" :header-row-style="caclHeaderStyle"  style="width: 100%" :max-height="tableHeight">
        <el-table-column label="商品信息" >
          <el-table-column label="商品图片" width="90">
            <template slot-scope="scope">
              <img v-if="scope.row.goodsPic" :src="scope.row.goodsPic.split(',')[0]"
                   style="width: 60px; height: 60px; border: none" />
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" label="商品名称" width="120">
          </el-table-column>
          <el-table-column label="商品描述">
            <el-table-column label="总计" width="120" prop="remark" >
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column label="销售">
          <el-table-column label="销量" prop="saleSum" sortable>
            <el-table-column :label="totalData.saleSum" width="100" prop="saleSum">
            </el-table-column>
          </el-table-column>
          <el-table-column label="销售金额" prop="saleMoney" sortable>
            <el-table-column :label="totalData.saleMoney" width="110" prop="saleMoney">
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column label="成本">
          <el-table-column label="采购金额" prop="purchaseMoney" sortable>
            <el-table-column :label="totalData.purchaseMoney" width="110" prop="purchaseMoney">
            </el-table-column>
          </el-table-column>
          <el-table-column label="服务费">
            <el-table-column :label="totalData.serviceMoney" prop="serviceMoney" width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column label="投放成本">
            <el-table-column :label="totalData.costMoney" prop="costMoney" width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column label="总成本" prop="costMoneySum" sortable>
            <el-table-column :label="totalData.costMoneySum" width="100" prop="costMoneySum">
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column label="售后">
          <el-table-column label="退款数">
            <el-table-column :label="totalData.refundSum" prop="refundSum" width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column label="订单退款">
            <el-table-column :label="totalData.refundMoney" prop="refundMoney" width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column label="采购退款">
            <el-table-column :label="totalData.purchaseRefundMoney" prop="purchaseRefundMoney" width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column label="服务费退回">
            <el-table-column :label="totalData.serviceRefundMoney" prop="serviceRefundMoney" width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column label="售后总额">
            <el-table-column :label="totalData.refundMoneySum" prop="refundMoneySum" width="100">
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column label="利润">
          <el-table-column label="总利润" prop="moneyGetSum" sortable>
            <el-table-column :label="totalData.moneyGetSum" width="120" prop="moneyGetSum" >
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="props">
              <el-button type="primary" @click="showDetail(props.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      </el-pagination>
    </el-card>


    <el-dialog title="详情" :visible.sync="dialogVisible" width="90%" fullscreen custom-class="detail-dialog">
      <el-table :data="detailList" :header-row-style="caclHeaderStyle" height="700" :cell-style="calcCellStyle" :span-method="objectSpanMethod2" style="width: 100%;margin: 0;">
        <el-table-column label="SKU信息" >
          <el-table-column label="SKU图片" width="120">
            <template slot-scope="scope">
              <img v-if="scope.row.goodsPic" :src="scope.row.goodsPic.split(',')[0]"
                   style="width: 60px; height: 60px; border: none" />
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" label="SKU名称" width="120">
          </el-table-column>
        </el-table-column>
        <el-table-column label="销售">
          <el-table-column prop="saleSum" label="销量" width="100">
          </el-table-column>
          <el-table-column prop="saleMoney" label="销售金额" width="100">
          </el-table-column>
        </el-table-column>
        <el-table-column label="成本">
          <el-table-column prop="purchaseMoney" label="采购金额" width="100">
          </el-table-column>
          <el-table-column prop="serviceMoney" label="服务费" width="100">
          </el-table-column>
          <el-table-column prop="costMoney" label="投放成本" width="100">
          </el-table-column>
          <el-table-column prop="costMoneySum" label="总成本" width="100">
          </el-table-column>
        </el-table-column>
        <el-table-column label="售后">
          <el-table-column prop="refundSum" label="退款数" width="100">
          </el-table-column>
          <el-table-column prop="refundMoney" label="订单退款" width="100">
          </el-table-column>
          <el-table-column prop="purchaseRefundMoney" label="采购退款" width="100">
          </el-table-column>
          <el-table-column prop="serviceRefundMoney" label="服务费退回" width="100">
          </el-table-column>
          <el-table-column prop="refundMoneySum" label="售后总额" width="100">
          </el-table-column>
        </el-table-column>
        <el-table-column label="利润">
          <el-table-column prop="moneyGetSum" label="总利润" >
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
//
<script>
export default {

  data () {
    return {
      list: [],
      dialogVisible: false,
      selectDateValue: null,
      params: {
        beginDate: '',
        endDate: '',
        remark: '',
        goodsId: '',
        groupId: '',
        currentPage: 1,
        pageSize: 50
      },
      statusList: [
        {key: '近1天', value: 1},
        {key: '近7天', value: 7},
        {key: '近30天', value: 30},
      ],  
      goodsList: [],
      groupList: [],
      detailList: [],
      tableHeight: 500,
      totalData: {
        costMoney: "",
        costMoneySum: "",
        moneyGetSum: "",
        purchaseMoney: "",
        purchaseRefundMoney: "",
        refundMoney: "",
        refundMoneySum: "",
        refundSum: "",
        resultParamsFinals: "",
        saleMoney: "",
        saleSum: "",
        serviceMoney: "",
        serviceRefundMoney: "",
      },
    }
  },
  created () {
    // this.GetList();
    this.selectDate(30)
    this.$api.GetGoodsGroupList({currentPage: 1, pageSize: 200,}).then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败:' + (res.message || ''))

        this.groupList = res.data
    })
    this.$api.GetGoodsList({currentPage: 1, pageSize: 200,}).then((res) => {
        if (res.code !== 200) return this.$message.error('请求失败:' + (res.message || ''))

        this.goodsList = res.data
    })
    setTimeout(() => {
      this.tableHeight = document.querySelector('.el-main').offsetHeight - 270
    }, 500)
    window.onresize = () => {
      this.tableHeight = document.querySelector('.el-main').offsetHeight - 270
    }
  },

  methods: {
    calcCellStyle({row, column, rowIndex, columnIndex}){
      if(columnIndex == 1 && rowIndex == 0){
          return 'font-size: 18px;text-align: right;font-weight: bold;'
      }else if(rowIndex == 0){
          return 'font-size: 18px;color: #333;font-weight: bold;'
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
      if(columnIndex == 0 && rowIndex == 0){
          return [0, 0]
      }else if(columnIndex == 1 && rowIndex == 0){
          return [1, 3]
      }else if(columnIndex == 2 && rowIndex == 0){
          return [0, 0]
      }
      return [1, 1]
    },
    objectSpanMethod2({ row, column, rowIndex, columnIndex }){
      if(columnIndex == 0 && rowIndex == 0){
          return [0, 0]
      }else if(columnIndex == 1 && rowIndex == 0){
          return [1, 2]
      }
      return [1, 1]
    },
    caclHeaderStyle({rowIndex}){
      if(rowIndex == 0){
        return 'font-size: 18px;color: #333;'
      }
    },
    // 选中页面值
    handleSizeChange (newSize) {
      this.params.pageSize = newSize
      this.GetList()
    },
    //当页面变动的时候
    handleCurrentChange (NewPage) {
      this.params.currentPage = NewPage
      this.GetList()
    },
    selectDate(val){
      this.selectDateValue = val;
      const curDate = new Date();
      this.params.endDate = new Date(curDate.setDate(curDate.getDate() - 1)).format('yyyy-MM-dd');
      this.params.beginDate = new Date(curDate.setDate(curDate.getDate() - (val - 1))).format('yyyy-MM-dd')
      this.search();
    },
    search(){
      let {beginDate, endDate} = this.params;
      if((beginDate && !endDate) || (!beginDate && endDate)){
        alert('请输入完成的时间段')
        return
      }
      if(new Date(endDate).getTime() < new Date(beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      this.params.currentPage = 1;
      this.GetList();
    },
    GetList () {
      this.$api.databoardList(this.params).then((res) => {
        if (res.code !== 200) this.$message.error('请求失败')
        const { resultParamsFinal, resultParamsFinalList} = res.data;
        // resultParamsFinal.goodsName = '总计';
        Object.keys(resultParamsFinal).map(o => {
          this.totalData[o] = resultParamsFinal[o]? resultParamsFinal[o].toString() : '0';
        })
        this.list = [...resultParamsFinalList]
      })
    },
    
    showDetail (row) {
      const firstRow = {...row};
      delete firstRow.resultParamsFinals;
      firstRow.goodsName = '总计';
      this.detailList = [firstRow, ...row.resultParamsFinals];
      this.dialogVisible = true
    },

  },
}
</script>
<style lang="scss" >
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:150px;
    margin-right: 5px;
}
.search-select{
  width:140px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
.status-item{
  padding: 4px 6px;
  border: 1px solid #ddd;
  margin-left: 5px;
  border-radius: 5px;
  &.active{
    background: #1b9aee;
    border-color: #1b9aee;
    color: #fff;
  }
}
.detail-dialog{
  .el-dialog__body{
    padding-top: 0;
  }
}
</style>
